body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p{
  color: #585856;
}

.app{
  background-color: #fff;
  display: flex;
}

/* SIDEBAR NAVIGATION */

.sidebar {
  width: 275px;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  background-color: #15150f;
  top: 0;
  flex-shrink: 0;
  z-index: 1000;
}
.browse nav{
  margin-bottom: 2rem;
}

.browse nav ul {
  padding: 0;
  margin: 0;
}

.browse nav li{
  list-style: none;
  border-left: 5px solid transparent;
  padding: 0;
  margin: 0;
}

.browse nav li, .browse nav li a{
  transition: .3s ease-in-out;
}

.browse nav li a {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  margin: 0;
}

.browse nav li a span {
  margin-left: 0.5rem;
}

.browse nav li a{
  padding-right: 1rem;
}

@media screen and (min-width: 801px) {
  .browse nav li:hover, .browse nav li.active {
    background-color: #fff;
    border-left-color: #6d233e;
  }

  .browse nav li:hover a, .browse nav li.active a {
    color: #15150f;
  }

  .browse nav li a {
    margin: 0.33rem 0;
  }
}

.browse nav a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
}

.browse p.devs {
  padding: 0 2rem 0 1rem;
}

.logo{
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
  background: #6d233e;
  font-family: 'Ibarra Real Nova', serif;
}

.browse{
  color: #585856;
  font-size: 0.7rem;
  padding: 2rem 0 2rem 2rem;
}


.browse h3{
  color: #585856;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  text-transform: uppercase;
}


@media screen and (max-width: 800px) {
  .sidebar {
    width: 68px;
  }
  .hideOnMobile {
    display: none;
  }
  .logo {
    height: 60px;
  }
  .logo > a > div {
    width: 50px !important;
    height: 50px !important;
  }
  .browse nav ul li{
    list-style: none;
    line-height: 50px;
  }
  .browse{
    padding: 0;
  }
  .browse li {
    padding: 0 .75rem;
  }
  .browse li a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
  }
  .app{
    flex-direction: column;
  }
  .sidebar{
    height: 50px;
    width: 100%;
    display: flex;
  }
  .browse nav ul {
    display: flex;
  }
  .logo {
    height: 50px;
  }
  .browse nav li {
    padding: 0;
    margin: 0;
    margin-left: 1rem;
  }
  .browse nav li a {
    line-height: 50px;
    height: 50px;
  }
  .browse nav ul li {
    line-height: 50px;
  }
}

/* Content */
.content{
  margin: 0 auto;
  width: 100%;
  max-width: 780px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.content p, .content a{
  padding: 0.5rem 0;
}
.content p{
  color: #323d3a;
}
.content a{
  text-decoration: none;
  color: #6d233e;
}

.bookslist {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem 1.5rem;
  background-color: #fff;
  color: #444;
}

.wrapper {
  width: 100%;
  padding: 0 1rem 0 1rem;
  min-width: 100px;
}



@media screen and (max-width: 1200px) {
  .content{
    max-width: 600px;
  }
  .bookslist{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .content{
    max-width: 480px;
  }
  .bookslist{
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem 1rem;
  }
}

.cntbottom {
  margin-bottom: 2rem;
}

.bookitem{
  margin: 0;
  padding: 0;
}

.bookitem a {
  text-decoration: none;
}

.bookitem h2.booktitle {
  font-size: .8rem;
  color: #323d3a;
  font-weight: normal;
  padding: 0;
  margin: 0.1rem 0 0 0;
  transition: 0.2s ease-in-out;
}

.bookitem:hover h2.booktitle{
  color: #6d233e;
}

.bookitem h3.authorname {
  font-size: 0.6rem;
  color: #899c97;
  font-weight: lighter;
  padding: 0;
  margin: 0.25rem 0 0 0;
  transition: 0.2s ease-in-out;
}

.bookitem:hover h3.authorname{
  color: #323d3a;
}

.bookitem .image{
  height: inherit;
  transition: 0.2s ease-in-out;
}

.bookitem:hover .image {
  background-color: #111;
}

.bookitem img.cover {
  width: 100%;
  height: auto;
  vertical-align: top;
  transition: 0.2s ease-in-out;
}

.bookitem:hover img.cover {
  filter: opacity(30%);
}

.componentTitle {
  font-size: 2rem;
  color: #323d3a;
  font-weight: lighter;
  padding-bottom: 0.5rem;
}

.componentSubTitle {
  font-size: 1.3rem;
  color: #323d3a;
  font-weight: lighter;
}

.infinite {
  overflow: hidden;
}

.cats{
  padding-left: 2rem;
  padding-right: 2rem;
}

.cats ul, .cats li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cats li{
  padding: 0.25rem 0;
}

.cats a{
  text-decoration: none;
  color: #585856;
}

.cats a:hover {
  color: #899c97;
}

/* PROFILE */

.profile {
  width: 100%;
  display: flex;
  align-items: center;
}
.profile img {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  padding: 0.5rem;
  flex-shrink: 0;
}
.profile .profile-content {
  padding-left: 1rem;
}
.profile p {
  color: #585856;
  line-height: 1.5;
  padding: 0;
  font-size: 1.1rem;
}

.profile .icon {
  margin-top: 0.1rem;
  display: flex;
}

@media screen and (max-width: 800px) {
  .profile {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .profile p, .profile img {
    width: 100%;
    text-align: center;
  }
  .profile img {
    max-width: 200px;
  }
  .profile .icon {
    justify-content: center;
  }
}

/* Bookdetail */
.bookdetail {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .bookdetail {
    flex-direction: column;
    justify-content: center;
  }
  .bookdetail .detail-image {
    display: flex;
    justify-content: center;
  }
}

.bookdetail > div {
  padding: 1rem;
}

.bookdetail .belowComponentTitle{
  padding: 0;
  margin-top: -0.7rem;
}

.bookdetail .wysiwyg{
  line-height: 1.5;
  font-size: 1rem;
}

.bookdetail .metadata{
  color: #555;
}